import store from '@/store'

const { congressAll } = store.state.congress
const { user } = store.state.auth
const congressChil = []
const menu = []
//
menu.push({
  title: 'Inicio',
  route: 'home_plataforma',
  icon: 'HomeIcon',
})
if (process.env.VUE_APP_MODULE_CURSOS === 'true') {
  menu.push({
    title: 'Cursos',
    icon: 'FilmIcon',
    route: 'cursos',
  })
}
if (process.env.VUE_APP_MODULE_CONGRESSOS === 'true') {
  menu.push({
    title: 'Congressos',
    icon: 'MonitorIcon',
    route: 'congress_home',
  })
}
if (process.env.VUE_APP_MODULE_LIVES === 'true') {
  menu.push({
    title: 'Lives',
    icon: 'PlayIcon',
    route: 'lives',
  })
}
if (process.env.VUE_APP_MODULE_PODCASTS === 'true') {
  menu.push({
    title: 'Podcasts',
    icon: 'HeadphonesIcon',
    route: 'podcasts',
  })
}
if (process.env.VUE_APP_MODULE_PUBLICACOES === 'true') {
  menu.push({
    title: 'Publicações',
    route: 'list_magazine',
    icon: 'BookOpenIcon',
  })
}


if (process.env.VUE_APP_MODULE_EVENTOS === 'true') {
  menu.push({
    title: 'Eventos',
    icon: 'CalendarIcon',
    route: 'events_commun',
  })
}

if (process.env.VUE_APP_MODULE_CERTIFICADOS === 'true') {
  menu.push({
    title: 'Certificados',
    route: 'Certificate',
    icon: 'DownloadIcon',
  })
}
export default menu
